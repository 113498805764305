import PropTypes from 'prop-types';
import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import styled from 'styled-components';
import Divider from './divider.js';
import AutoplaySlider from "./autoplay-slider";
import backgroundBG from '../images/banner-bg.jpg';
import FeaturedArticles from './featured-articles';
import TopTrendingSkills from './top-trending-skills';
import HireAndApply from './hire-and-apply';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/main.css'
import '../css/main.scss'

const MainWrapper = styled.div`
  margin: 0;
`;

const Main = ({ siteTitle }) => (
    <MainWrapper>
        <main>
            <section className="banner" style={{ backgroundImage: `url(${backgroundBG})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner__copy">
                                <h1>Hire The Best <strong>Technical Talent</strong></h1>
                                <p className="p--hg u-color-white my-5 my-lg-4">
                                    Codestaff is an exclusive network of the top freelance software developers, designers, finance experts,
                            <br className="d-none d-lg-block" />
                                    <span className="d-lg-none">&nbsp;</span>
                                    and project managers in the world. Top companies rely on
                                    Codestaff freelancers for their most
                            <br className="d-none d-lg-block" />
                                    <span className="d-lg-none">&nbsp;</span>
                                    important projects. </p>
                                <div className="banner__btns">
                                    <Link to='/hire/' className="btn btn--lg">HIRE TOP TALENT</Link>
                                    <Link to='/apply/' className="btn btn--lg btn--outline-white">APPLY AS A FREELANCER</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="experts">
                <div className="container onTop">
                    <div className="row">
                        <div className="col-12">
                            <div className="headline how__headline">
                                <h2 className="u-uppercase headline__title">BUILD trusted experts for your team!</h2>
                                <p className="p--hg u-color-gray-3 u-ls-0 headline__text mt-2 mb-4">Work with hand-picked talent, evaluated with our thorough screening process</p>
                                <Divider />
                            </div>
                        </div>
                    </div>
                    <div className="row h-100 align-items-stretch">
                        <div className="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-md-0 offset-lg-0">
                            <div className="experts__slider">
                                <div className="experts__slider-item">
                                    <div className="experts__slider-item-media">
                                        <StaticImage src='../images/dean-witcraft.jpg' alt={'Profile'} className='experts__slider-item-img' />
                                    </div>
                                    <div className="experts__slider-item-copy">
                                        <h4 className="h4--lg u-color-white u-font-titles">Dean Witcraft</h4>
                                        <p className="p--xl u-color-white mt-2 mt-md-1">Software Engineer, Web Developer</p>
                                        <p className="p--xl u-color-secondary-lgt mt-3 mb-5 mb-md-4 mt-md-3">As a former business owner I bring a slightly different perspective to software development. I relish the constant learning required of this career.</p>
                                        <Link to='/contact/' className="btn btn--outline-white">HIRE</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row align-items-stretch h-100">
                                <div className="col-6 experts__category">
                                    <div className="feature-box">
                                        <StaticImage src="../images/i-developers.png" alt="Icon" className='feature-box__icon' imgClassName='staticImageFit' />
                                        <h3 className="mt-3 mb-2">Software Development</h3>
                                        <p className="p--lg u-color-gray-2">Find a Local Entry Level or Senior Software Developer for your application, architecture or program.</p>
                                        <Link to='/software-developer/' className="btn btn--sm mt-auto">FIND SOFTWARE DEVELOPERS</Link>
                                    </div>
                                </div>
                                <div className="col-6 experts__category">
                                    <div className="feature-box">
                                        <StaticImage src="../images/i-project-managers.png" alt="Icon" className='feature-box__icon' imgClassName='staticImageFit' />
                                        <h3 className="mt-3 mb-2">Web Design</h3>
                                        <p className="p--lg u-color-gray-2">Easily Hire the Best Web Designers in the United States for your App, Website, Platform or graphic project.</p>
                                        <Link to='/design/' className="btn btn--sm mt-auto">FIND WEB DESIGNERS</Link>
                                    </div>
                                </div>
                                <div className="col-6 experts__category mb-md-0">
                                    <div className="feature-box">
                                    <StaticImage src="../images/i-designers.png" alt="Icon" className="feature-box__icon"imgClassName='staticImageFit' />
                                        <h3 className="mt-3 mb-2">Logo Design</h3>
                                        <p className="p--lg u-color-gray-2">We know how important hiring a logo designer is to your business. Find the top logo designers local to you.</p>
                                        <Link to='/logo-designer/' className="btn btn--sm mt-auto">FIND LOGO DESIGNERS</Link>
                                    </div>
                                </div>
                                <div className="col-6 experts__category mb-md-0">
                                    <div className="feature-box">
                                        <StaticImage src="../images/i-qa.png" alt="Icon" className="feature-box__icon" imgClassName='staticImageFit' />
                                        <h3 className="mt-3 mb-2">App Development</h3>
                                        <p className="p--lg u-color-gray-2">Hire App Development Freelancers for your Android App, Mobile App or IOS Application at a reasonable cost.</p>
                                        <Link to='/mobile-app-developer/' className="btn btn--sm mt-auto">FIND APP DEVELOPERS</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="how">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="headline how__headline">
                                <h2 className="u-uppercase headline__title">HOW CODESTAFF WORKS</h2>
                                <p className="p--hg u-color-gray-3 u-ls-0 headline__text mt-2 mb-4">Improved model for building your own teams</p>
                                <Divider />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-lg-3 mb-5 mb-lg-0">
                            <div className="how__step">
                                <StaticImage src="../images/h1-description.jpg" alt="Icon" className="how__step-img" />
                                <div className="how__step-copy">
                                    <div className="how__step-number"><span>1</span></div>
                                    <h5 className="u-color-secondary u-font-titles how__step-label">Tell us what kind of<br />freelance talent you need.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 mb-5 mb-lg-0">
                            <div className="how__step">
                                <StaticImage src="../images/h2-perfectmatch.jpg" alt="Icon" className="how__step-img" />
                                <div className="how__step-copy">
                                    <div className="how__step-number"><span>2</span></div>
                                    <h5 className="u-color-secondary u-font-titles how__step-label">We find you<br />the perfect match.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="how__step">
                                <StaticImage src="../images/h3-skillsoffered.jpg" alt="Icon" className="how__step-img" />
                                <div className="how__step-copy">
                                    <div className="how__step-number"><span>3</span></div>
                                    <h5 className="u-color-secondary u-font-titles how__step-label">Work with them<br />at no-risk.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="how__step">
                                <StaticImage src="../images/h4-myteam.jpg" alt="Icon" className="how__step-img" />
                                <div className="how__step-copy">
                                    <div className="how__step-number"><span>4</span></div>
                                    <h5 className="u-color-secondary u-font-titles how__step-label">They become part<br />of your team.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="balance">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="headline headline--alt balance__headline">
                                <h2 className="h2--xl u-uppercase">find the right balance<br className="d-none d-md-block" /><span className="d-md-none">&nbsp;</span>between talent and management </h2>
                                <p className="p--hg u-color-gray-3 u-ls-0 headline__text">Get your hands dirty or stand back and watch the magic</p>
                                <Divider alt={true} />
                            </div>
                            <div className="balance__boxes">
                                <div className="img-box">
                                    <div className="img-box__media">
                                        <StaticImage src="../images/work-directly.png" alt="Icon" className="img-box__media-img" />
                                    </div>
                                    <div className="img-box__copy">
                                        <h3 className="u-color-white mb-2">WORK DIRECTLY WITH YOUR TALENT</h3>
                                        <h5 className="u-color-white u-weight-reg">We source the best talent from our private network of software developers and designers. We help you put the right team in place that you manage.</h5>
                                    </div>
                                </div>
                                <div className="img-box">
                                    <div className="img-box__media">
                                        <StaticImage src="../images/have-leader.png" alt="Icon" className="img-box__media-img" />
                                    </div>
                                    <div className="img-box__copy">
                                        <h3 className="u-color-white mb-2">HAVE A LEADER MANAGE YOUR TEAM</h3>
                                        <h5 className="u-color-white u-weight-reg">Software Architects and Certified Scrum Masters (CSMs) lead your project using Agile methodology.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="headline testimonials__headline">
                                <h2 className="u-uppercase headline__title">What People Will Say About Us After<br className="d-none d-md-block" /><span className="d-md-none">&nbsp;</span>Using Our Services</h2>
                                <p className="p--hg u-color-gray-3 u-ls-0 headline__text">Confidently filling your organizations' skill gap</p>
                                <Divider />
                            </div>
                            <div className="testimonials__slider">
                                <AutoplaySlider />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog__main">
                                <div className="blog__main-content">
                                    <FeaturedArticles />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>
);

Main.propTypes = {
    siteTitle: PropTypes.string
};

Main.defaultProps = {
    siteTitle: ``
};

export default Main;
