import React from 'react'
import Layout from '../components/layout'
import Main from '../components/main'
import Seo from '../components/seo'

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Main />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Codestaff'
  const description =
    'Hire the best at Codestaff. Get the top 1% of professionals to join your team.'
  return <Seo type="page" title={title} description={description} />
}

export default IndexPage
