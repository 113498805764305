import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const FeaturedArticles = () => (
	<section className="blog">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="featured-articles">
						<div className="featured-articles__intro">
							<h2 className="u-uppercase">Amazon Web Services Freelancer Resources. AWS is Trending in 2022</h2>
							<p className="p--hg u-ls-0 u-color-gray-3">
								Learn some of the trending topics for Amazon Web Services in 2022. Lets explore some simple, intermediate and advanced topics that will be helpful. 
							</p>
						</div>
						<div className="featured-articles__main">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
									<article className="article-box article-box--lg">
										<a href="https://linked.codestaff.io/" className="article-box__img-wrapper">
											<StaticImage
												src="../images/linkedin-marketing1.jpg"
												alt={'Marketing Target'}
												className="article-box__img"
											/>
										</a>
										<div className="article-box__copy">
											<p className="article-box__subtitle">
												<Link to="/">AWS AMAZON WEB SERVICES</Link>
											</p>
											<h4 className="h4--lg article-box__title">
                                            <Link to="/">AWS WordPress Hosting </Link>
											</h4>
											<p className="article-box__text">
												Hosting your website is something everyone will have to deal with. Hosting a websit on AWS
												is that much more complex. In this article we talk about setting up your site on aws and
												we also talk about signing up for codestaff hosting on aws. 
											</p>
											<p className="p--md">
												BY{' '}
												<a
													href="https://www.linkedin.com/in/devin-mallonee/"
													className="article-box__author"
												>
													BLD Brand
												</a>{' '}
												- AWS Certified Expert @ BLD Brand
											</p>
										</div>
									</article>
								</div>
								<div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
									<article className="article-box article-box--lg">
										<Link to="/linkedin-marketing/" className="article-box__img-wrapper">
											<StaticImage
												src="../images/linkedin-marketing1.jpg"
												alt={'Article'}
												className={'article-box__img'}
											/>
										</Link>
										<div className="article-box__copy">
											<p className="article-box__subtitle">
												<Link to="/linkedin-marketing/">AWS AMAZON WEB SERVICES</Link>
											</p>
											<h4 className="h4--lg article-box__title">
												<Link to="/linkedin-marketing/">
													Getting Started with AWS Certifications in 2022
												</Link>
											</h4>
											<p className="article-box__text">
												LinkedIn is a social network with real professional applications. It is
												used by many professionals for the purposes of growing their
												professional connections...
											</p>
											<p className="p--md">
												BY{' '}
												<a
													href="https://www.linkedin.com/in/devin-mallonee/"
													className="article-box__author"
												>
													BLD Brand
												</a>{' '}
												- AWS Certified Expert @ BLD Brand
											</p>
										</div>
									</article>
								</div>
								<div className="col-12 col-md-6 col-lg-4">
									<article className="article-box article-box--lg">
										<Link to="/content-marketing-strategy/" className="article-box__img-wrapper">
											<StaticImage
												src="../images/linkedin-marketing1.jpg"
												alt={'Article'}
												className={'article-box__img'}
											/>
										</Link>
										<div className="article-box__copy">
											<p className="article-box__subtitle">
												<Link to="/content-marketing-strategy/">AWS AMAZON WEB SERVICES</Link>
											</p>
											<h4 className="h4--lg article-box__title">
												<Link to="/content-marketing-strategy/">
													We Tried AWS Cloud Practicioner Cloud Quest Learning For You
												</Link>
											</h4>
											<p className="article-box__text">
												AWS is always coming up with clever ways to teach people how to use 
												their extremely complex ecosystem. AWS Cloud Practioner looks like a 
												test to see if this learning is effecting on their most popular 
												introduction exam. We tried it for you. Check out what the Cloud Quest
												Dashboard and intro look like. 
												
											</p>
											<p className="p--md">
												BY{' '}
												<a
													href="https://www.linkedin.com/in/devin-mallonee/"
													className="article-box__author"
												>
													BLD Brand
												</a>{' '}
												- AWS Certified Expert @ BLD Brand
											</p>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default FeaturedArticles;
